const en={
    jiantizhongwen:'English',
    xianlu:'Line ',
    dianwei:'Point',
    wodezichan:'My assets',
    touzikuaixun:'Investment News',
    jiaoyiyonghu:'User',
    jiaoyipinglei:'Category',
    danlunhuoli:'Profit',
    jiaoyiriqi:'Date',
    dengluhouchakan:'View after login',
    shouye:'Home',
    jiaoyidating:'hall',
    yucun:'Prestore',
    zoushi:'Trend',
    wode:'Mine',
    zuiditouzi:'Minimum investment',
    zuidijinru:'Minimum entry',
    jichulicai:'Basic Financing',
    zishenlicai:'Senior Financing',
    dashilicai:'Master Financing',
    zhuanjialicai:'Expert Financing',
	基础理财:'Basic Financing',
    资深理财:'Senior Financing',
    大师理财:'Master Financing',
    专家理财:'Expert Financing',
    huiyuanquanyi:'Member benefits',
    zuoriyingli:'Profit yesterday',
    zongzichan:'Total assets',
    chucunjin:'Money',
    jinriyingli:'Profit today',
    zhuanchu:'Withdraw',
    cunru:'Deposit',
    zhanghuxinxni:'Account information',
    lishizhangdan:'Historical bills',
    zijinminxi:'Funding Details',
    renwuzhongxin:'Mission center',
    xiaoxigonggao:'News announcement',
    tuijianfenxiang:'Recommended to share',
    huodongzhongxin:'Activity Center',
    jibenziliao:'Basic information',
    dengluzhanghao:'Login account',
    nicheng:'Nick name',
    shezhi:'Settings',
    genggaixianlu:'Change line',
    guanyuwomen:'About us',
    xitongshezhi:'System settings',
    yuyanshezhi:'Language settings',
    tuichudenglu:'Logout',
    bangdingyinhangka:'Bind bank card',
    bangdingxuniqianbao:'Bind virtual wallet',
    anquanma:'Security code',
    xiugailenglumima:'Modify login password',
    yinhangkaguanli:'Bank card management',
    tianjiayinhangka:'Add a bank card',
    xuniqianbaoguanli:'Virtual wallet management',
    tianjiaxuniqianbao:'Add virtual wallet',
    chiakren:'Cardholder',
    yinhangkakahao:'Bank card number',
    yinhangmingcheng:'Bank name',
    yhkTps:'Please bind the cardholder own bank card',
    nixiqianbaodizhi:'Wallet address',
    xuniqianbaoleixing:'Wallet Type',
    xnqbTps:' Reminder: Please fill in the USDT address type, such as: trc20, erc20, omni. ',
    plchikaren:'Please enter cardholder',
    plkahao:'Please enter cardholder card number',
    plyinhangmingcheng:'Please enter bank name',
    plqbdz:'Please enter the virtual wallet address',
    plqblx:'Please enter virtual wallet type',
    shezhianquanma:'Set security code',
    yuananquanma:'Original security code',
    xinanquanma:'New security code',
    planquanma:'Please enter the original security code',
    plxinanquanma:'Please enter a new security code',
    queren:'Confirm',
	quxiao:'Cancel',
    anquanmaTps:' Please set a security code, not the same as the bank card password',
    anquanmaTpsTow:' Dear customer, this security code is the password when you transfer out. For security reasons, please try not to be the same as the login password',
    xiugaidenglu:'Modify login password',
    jiumima:'Old Password',
    xinmima:'New password',
    querenmima:'Confirm Password',
    pljiumima:'Please enter old password',
    plxinmima:'Please enter a new password',
    plquerenmima:'Please enter the password again',
    wancheng:'Complete',
    kaishitouzi:'Start investing',
    kaiTps:'When the current line is unavailable, try switching to other lines',
    zuiyou:'Optimal',
    dangaqianxianlu:'Current line',
    dangqianbanben:'Current version',
    banquansuoyou:'Copyright',
    yinglizhendong:'Earnings shock',
    gensuixitong:'Default',
    zhuanchujine:'Amount',
    plzhuanchu:'Please enter the transfer amount',
    zhuanchuTps:'Tips: If you have any questions, please contact the platform customer service.',
    xuanzeyh:'Please select a bank card for withdrawal',
    xuanzehb:'Please select a virtual wallet for withdrawal',
    tijiaoshenqing:'Submit application',
    shuruanquanma:'Please enter security code',
    xianshangcunru:'Online deposit',
    saomacunru:'USDT',
    wangyincunru:"Banking deposit",
    shijian:'Time',
    upshijian:'Starting time',
    downshijian:'End Time',
    wuTps:'No data ~',
    jiazaicx:'Reload',
    di:'Period ',
    lun:' ',
    weiyingli:'Unprofitable',
    yishouli:'Accepted',
    zhuangtai:'Status',
    chongzhi:'Reset',
    riqi:'Date',
    CapActive:{
        chongzhi:'Recharge',
        tixian:'Withdraw',
        goumai:'Buy',
        yingli:'Profit',
        zengsong:'Give away',
        weitongguo:'Did not pass',
        yitongguo:'Passed',
        yijujue:'Rejected',
    },
    zhanneixiaoxi:"Information",
    pingtaigonggao:'Announcement',
    fuzhilanjie:"copy Link",
    denglu:'Login',
    zhuce:'Register',
    jizhumima:'Remember',
    wangjimima:'Forgot password',
    youkedenglu:'Visitor login',
    zhucezhanghao:"Register account",
    plusername:'Please enter user name',
    plpassword:'Please enter password',
    wanchengzhuce:'Complete',
    yiyouzhanghao:'Have an account',
    qingdenglu:'Please sign in',
    remenhuati:'Hot topic',
    news1:'Original',
    news2:'Recommend',
    news3:'Asset',
    renliulan:' browse',
    hezhi:'And value',
    lunshu:'Period',

    做多:'Many',
    做空:'Short',
    多单:'Many Single',
    多双:'Many Double',
    空单:'Short Single',
    空双:'Short Double',
    平单:'Flat Single',
    平双:'Flat Double',
	极阴:'Cathode',
	极阳:'Anode',
    qi:'',
    juli:'distance',
    lunjiezhi:'end',
    yifengpan:'Stop betting',
    yiguanbi:'Closed',
    yonghu:'User',
    danlunjiaoyi:'Trade',
    caozuo:'Operate',
    pljoin:'Join the chat room successfully',
    pltingshou:'Product has been discontinued',
    gengou:"Follow",
	quedinggengou:"Are you sure to follow the purchase?",
	wanjia:"player",
	leibie:"category",
	jine:"amount",
    gouru:'Buy',
    zanweigouru:'Not yet purchased',
    qigou:' purchase',
    gourushuliang:'Purchase quantity',
    changgui:'Conventional',
    shuzi:'Number',
    yinglihezhi:'Profit and value',
    shouyijieshao:'Income',
    lijicunru:'Deposit',
    kaihaojieguo:'Record',
    kaihaoqushi:'Trend',
    chanpingshuoming:'Description',
    qingchuhuancun:'clear cache',
    youke:'Visitor',
	gongxi:'Congratulations to ',
	zai:' in the ',
	yingli:' profit ',
	xiadanchenggong:'successfully ordered',
	jiazaizhong:'加載中...',
	guanbitg:'closed, stop buying',
	xiangoudl:'Only follow the current round',
	liaotiantip:'The system has been banned, please choose to buy',
	tishi:'Tip',
	zhidao:'OK',
	zhuanchusb:'Failed to transfer out',
	zhuanchusbs:'Insufficient balance, transfer out failed',
	buyxq:'Purchase details',
	orderNo:'Bill No.',
	plan_name:'Hall information',
	buyxx:'Buy information',
	haoxx:'Number selection details',
	buysj:'Buy time',
	yilou: 'missing',
	fzjqb: 'Copied to clipboard',
	chanpin1: 'Sale time: every 3.5 minutes is a round, opening at 10:00AM on the same day and closing at 02:00AM the next day. ',
	chanpin2: 'There are 3 numbers from 0 to 9 in each round. The sum of the 3 numbers is the final result. You can invest in the result: Many, Short, Flat Single, Flat Double, Many Single, Many Double, Short Single, Short Double, Cathode, Anode, and number. ',
	Tips:{
        wjmm: 'Forgot your password, please contact customer service to modify it!',
        dlcg: 'Login successful',
        tjcg: 'Submitted successfully',
        zanwu: 'No data',
        buyizhi: 'The two passwords are inconsistent',
        zcbz: 'Your assets are insufficient, please deposit',
        pltxje: 'Please enter the withdrawal amount',
        zdtx: 'Minimum withdrawal 100',
		youke: 'Dear tourists: Please register as a full member and operate. ',
		szcg: 'set successfully',
     },
}
export default en;